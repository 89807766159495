import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  HeroTitle,
  PageTitle,
  Container,
  Grid,
  Column,
} from 'ascential-horizon'

const linkmapper = (page, index) => (
  <li key={`${page.name}_${index.toString()}`}>
    <Link to={page.url}>{page.name}</Link>
  </li>
)

const userPages = [
  { name: 'Status', url: '/userstatus' },
  { name: 'Cleanup', url: '/cleanup' },
]

const IndexPage = ({
  pageContext: {
    homePage,
    landingPages,
    articlePages,
    eventPages,
    categoryPages,
  },
}) => (
  <Container>
    <HeroTitle mb={6}>Horizon Index</HeroTitle>
    <Grid>
      <Column width={6}>
        <section>
          <PageTitle>Home Page</PageTitle>
          <ul>{homePage.map(linkmapper)}</ul>
        </section>
        <section>
          <PageTitle>User</PageTitle>
          <ul>{userPages.map(linkmapper)}</ul>
        </section>
        <section>
          <PageTitle>Landing Pages</PageTitle>
          <ul>{landingPages.map(linkmapper)}</ul>
        </section>
        <section>
          <PageTitle>Article Pages</PageTitle>
          <ul>{articlePages.map(linkmapper)}</ul>
        </section>
      </Column>
      <Column width={6}>
        <section>
          <PageTitle>Category Pages </PageTitle>
          <ul>{categoryPages.map(linkmapper)}</ul>
        </section>

        <section>
          <PageTitle>Event Pages </PageTitle>
          <ul>{eventPages.map(linkmapper)}</ul>
        </section>
      </Column>
    </Grid>
  </Container>
)

IndexPage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default IndexPage
